.quill-wrapper {
  width: 100%;
}
.quill {
  width: 100%;
  display: block;
  margin: 0;
}
.ql-editor {
  min-height: 10em;
}
